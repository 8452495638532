import React from "react";

import HeroStyles from './Hero.module.scss';

const Hero = ({ heading, intro, subheading = '', className }) => {
  return (
    <section className={`${HeroStyles.hero} ${className}`}>
      <h1>{heading}</h1>
      {subheading && (
        <h2>{subheading}</h2>
      )}
      {intro && (
        <p>{intro}</p>
      )}
    </section>
  );
};

export default Hero;
