import React, { useEffect, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import FullViewModalStyles from "./FullViewModal.module.scss";

const FullViewModal = ({ selectedImage, closeModal, fadingTransition }) => {
  const [fadeDirection, setFadeDirection] = useState("fadeIn");
  useEffect(() => {
    setFadeDirection(fadingTransition);
  }, [fadingTransition]);

  return (
    <div
      className={`${FullViewModalStyles.dimBackground} ${FullViewModalStyles[fadeDirection]}`}
      onAnimationEnd={() => {
        if (fadeDirection === "fadeOut") {
          closeModal();
        }
      }}
    >
      <div className={FullViewModalStyles.imagePreview}>
        <GatsbyImage
          image={getImage(selectedImage.photo)}
          alt={selectedImage.photo.description}
          className={FullViewModalStyles.image}
        />
        <h3>{selectedImage.name}</h3>
      </div>
      <button
        type="button"
        className={FullViewModalStyles.closeModal}
        onClick={() => setFadeDirection("fadeOut")}
        aria-label="Close image full view and return to site"
      >
        +
      </button>
    </div>
  );
};

export default FullViewModal;
