import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout/Layout";
import Head from "../components/Head/Head";
import Hero from "../components/Hero/Hero";

import GalleryStyles from "./gallery.module.scss";
import FullViewModal from "../components/FullViewModal/FullViewModal";

const Gallery = ({ data, location }) => {
  const { pageDetails, allTagsQuery, allTattoosQuery } = data;
  const allTags = allTagsQuery.edges.map((edge) => edge.node);
  const allTattoos = allTattoosQuery.edges.map((edge) => edge.node);

  const [viewFullImage, setViewFullImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});

  const [modalRendered, setModalRendered] = useState(false);
  const [fadingTransition, setFadingTransition] = useState('');

  const findSelectedImage = (imageId, allImages) => {
    const foundImage = allImages.find((image) => image.id === imageId);
    return foundImage;
  };

  const handleShowFullImage = (imageId) => {
    setSelectedImage(findSelectedImage(imageId, allTattoos));
    setViewFullImage(true);
  };

  useEffect(() => {
    if (viewFullImage) {
      setModalRendered(true);
      setFadingTransition('fadeIn')
    } else {
      setFadingTransition('fadeOut')
    }
  }, [viewFullImage]);

  const handleCloseModal = () => {
    setViewFullImage(false);
    setModalRendered(false);
  };

  return (
    <Layout>
      <Head
        metaContent={pageDetails.metaContent}
        location={location.pathname}
      />
      <Hero
        heading={pageDetails.pageHeader}
        intro={pageDetails.pageContent.pageContent}
      />
      <section id="gallery" className={GalleryStyles.gallery}>
        <h2>Filter by my different styles:</h2>
        <ul className={GalleryStyles.tagList}>
          <li className={GalleryStyles.tag}>
            <Link
              to="/gallery/#gallery"
              className={
                location.pathname === `/gallery/` ? "active" : "spread-underline"
              }
            >
              All Tattoos
            </Link>
          </li>
          {allTags.map((tag) => (
            <li key={tag.id} className={GalleryStyles.tag}>
              <Link
                className="spread-underline"
                to={`/gallery/${tag.slug}#gallery`}
              >
                {tag.name}
              </Link>
            </li>
          ))}
        </ul>
        <ul className={GalleryStyles.allPhotos}>
          {allTattoos.map((tattoo) => (
            <li key={tattoo.id} className={GalleryStyles.tattoo}>
              <button
                type="button"
                className={GalleryStyles.buttonWrapper}
                onClick={() => handleShowFullImage(tattoo.id)}
                aria-label="View the full image in a separate pop up"
              >
                <GatsbyImage
                  image={getImage(tattoo.photo)}
                  alt={tattoo.photo.description}
                  className={GalleryStyles.tattooImage}
                />
              </button>
            </li>
          ))}
        </ul>
      </section>
      {modalRendered && (
        <FullViewModal
          selectedImage={selectedImage}
          fadingTransition={fadingTransition}
          closeModal={handleCloseModal}
        />
      )}
    </Layout>
  );
};

export default Gallery;

export const query = graphql`
  query {
    pageDetails: contentfulGalleryPage {
      metaContent {
        title
        description {
          description
        }
        image {
          file {
            url
          }
        }
      }
      pageHeader
      pageContent {
        pageContent
      }
    }
    allTagsQuery: allContentfulTag {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    allTattoosQuery: allContentfulTattoo {
      edges {
        node {
          id
          name
          photo {
            description
            gatsbyImageData
          }
        }
      }
    }
  }
`;
